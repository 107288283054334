<template>
  <div>
    <template>
      <user-view-user-info />
      <user-tab />
    </template>
  </div>
</template>

<script>
import UserViewUserInfo from '@/views/apps/user/users-view/UserViewUserInfo.vue'
import UserTab from '@/views/apps/user/users-view/users-tab/UserTab.vue'

export default {
  components: {

    // Local Components
    UserViewUserInfo,
    UserTab,
  },
}
</script>
