<template>
  <div>
    <b-card no-body>
      <!-- Search Condition -->
      <b-row class="justify-content-between">
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="구분"
            label-for="message-select"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-md="left"
          >
            <b-form-select
              id="message-select"
              v-model="selectedMessageOptions"
              class="font-small-3 half-width"
              :options="messageOptions"
              size="sm"
              style="color:rgb(229,166,48) !important; opacity: 0.7;"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            :label="selectedMessageOptions === 'rcv' ? '수신일자' : '발신일자'"
            label-for="message-period"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group
              id="message-period"
              class="calendar-size font-small-3"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class=""
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class=""
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Table -->
      <b-row class="justify-content-between">
        <!-- Table Fitler -->
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="Show"
            label-for="message-perPage"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-sm="left"
          >
            <b-form-select
              id="message-perPage"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              size="sm"
              :clearable="false"
              class="half-width"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            label="검색"
            label-for="message-searchFilter"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group size="sm">
              <b-form-input
                id="message-searchFilter"
                v-model="searchFilter"
                type="search"
                placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Table Content -->
        <b-col sm="12">
          <b-table
            responsive
            striped
            hover
            small
            show-empty
            empty-text="No matching records found"
            class="no-select"
            style="text-align: center; font-size: small"
            :items="paginatedItems"
            :fields="messagesHistoryFields"
            @row-clicked="fncToggleRowDetails"
          >
            <template #cell(no)="data">
              <div style="text-align: center; min-width: 3rem">
                {{ data.idx }}
              </div>
            </template>
            <template #cell(reads)="data">
              <div style="align-items: center; min-width: 9rem;">
                <span style="text-align: center; flex-grow: 1; max-width: 4rem;">
                  {{ fncReadsAndTotalCount(data.item.receivers) }}
                </span>
                <b-badge variant="primary">
                  <feather-icon
                    type="button"
                    icon="UsersIcon"
                    size="12"
                    class="text-body"
                    style="color:white !important;"
                    @click.stop="fncOpenModal(data.item.receivers)"
                  />
                </b-badge>
              </div>
            </template>
            <template #cell(receiverIp)="data">
              <div
                v-b-tooltip.hover.bottom.v-danger="data.item.receiverIp"
                size="sm"
                style="text-align: center;"
              >
                {{ truncateText(data.item.receiverIp, 15) }}
              </div>
            </template>
            <template #row-details="row">
              <div v-if="row.item._showDetails">
                <b-card
                  class="p-0 text-left"
                  style="background-color: #212026;"
                >
                  <b> 내용 : </b> {{ row.item.message }}
                </b-card>
              </div>
            </template>
          </b-table>
        </b-col>

        <!-- Table Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
          style="margin-bottom: 0.5rem;"
        >
          <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} 건 </span>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="messagesHistoySendReceiversInfoModal"
      no-close-on-backdrop
      hide-footer
      centered
      @hidden="fncCloseModal"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          수신 확인
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click.stop="fncCloseModal"
          />
        </div>
      </template>
      <b-card no-body>
        <div class="table-container">
          <b-table
            responsive
            striped
            small
            :items="receiversItems"
            :fields="receiversFields"
            class="text-center table-scroll"
          >
            <template #cell(idx)="data">
              <div style="text-align: center; min-width: 3rem">
                {{ data.index+1 }}
              </div>
            </template>
            <template #cell(isRead)="data">
              <b-badge :variant="data.item.isRead ? 'primary': 'success'">
                {{ data.item.isRead ? '읽음' : '안읽음' }}
              </b-badge>
            </template>
          </b-table>
        </div>
        <div>
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <div class="text-center">
                <b-button
                  ref="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  size="sm"
                  @click.stop="fncCloseModal"
                >
                  닫기
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend,
  BFormSelect,
  BButton, BFormDatepicker,
  BTable, BPagination,
  VBTooltip, VBToggle, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_MESSAGES } from '@/store/messages/action'

import * as moment from 'moment-timezone'

const messagesStore = createNamespacedHelpers('messagesStore')

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

export default {
  name: 'UsersTabMessages',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BButton,
    BFormDatepicker,
    BTable,
    BPagination,
    BBadge,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userView')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),

      // Searh Conditon
      selectedMessageOptions: 'rcv',
      messageOptions: [
        { value: 'rcv', text: '수신' },
        { value: 'snd', text: '발신' },
      ],
      periodFrom: moment(new Date()).startOf('month').format(fmt1),
      periodTo: moment(new Date()).endOf('month').format(fmt1),

      // TableOption
      searchFilter: '',
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)

      // modal
      receiversItems: null,
      receiversFields: [
        { key: 'idx', label: 'No.' },
        { key: 'receiverId', label: '수신 회원' },
        { key: 'isRead', label: '읽음 / 안읽음' },
        { key: 'receiverIp', label: '받는 IP' },
        { key: 'readAt', label: '읽은 시간' },
      ],
    }
  },
  computed: {
    ...messagesStore.mapGetters({
      fetchMessagesReceiveHistory: 'fetchMessagesReceiveHistory',
      fetchMessagesSendHistory: 'fetchMessagesSendHistory',
    }),
    messagesHistoryItems() {
      const combinedMessages = []

      // 수신쪽지 데이터를 합치고 type 속성을 추가
      this.fetchMessagesReceiveHistory.forEach(item => {
        combinedMessages.push({
          ...item,
          type: 'rcv',
        })
      })

      // 발신쪽지 데이터를 합치고 type 속성을 추가
      this.fetchMessagesSendHistory.forEach(item => {
        const readCnt = item.receivers.filter(receiver => receiver.isRead).length
        combinedMessages.push({
          ...item,
          readCnt,
          type: 'snd',
        })
      })
      console.log('messagesHistoryItems', combinedMessages.length)
      return combinedMessages
    },
    filteredMessagesHistoryItems() {
      return this.messagesHistoryItems.filter(item => item.type === this.selectedMessageOptions)
    },
    messagesHistoryFields() {
      const commonFields = [
        { key: 'idx', label: 'No.' },
        { key: 'site', label: '사이트' },
        { key: 'title', label: '제목' },
      ]

      const receiveFields = [
        { key: 'senderId', label: '보낸 사람' },
        { key: 'senderIp', label: '보낸 사람' },
        { key: 'receiveAt', label: '수신 일자' },
        { key: 'readAt', label: '읽음' },
      ]

      const sendFields = [
        { key: 'reads', label: '받는 회원' },
        { key: 'sendAt', label: '발신 일자' },
      ]

      const selectedFields = this.selectedMessageOptions === 'rcv'
        ? commonFields.concat(receiveFields)
        : commonFields.concat(sendFields)

      return selectedFields
    },

    // 검색필터된 Items
    filteredItems() {
      let filteredItems = this.filteredMessagesHistoryItems.reverse()
      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      // console.log('filteredItems', filteredItems)
      return filteredItems
    },
    // 검색필터된 Items => 페이징처리된 Item
    paginatedItems() {
      const totalFilteredItems = this.filteredItems.length
      const start = totalFilteredItems - (this.currentPage * this.perPage)
      const end = start + this.perPage

      const paginatedItems = this.filteredItems.slice(Math.max(0, start), end).reverse()

      this.totalRows = totalFilteredItems
      this.pageFrom = (this.currentPage - 1) * this.perPage + 1 // 현재 페이지의 첫 번째 항목
      this.pageTo = Math.min(this.totalRows, this.currentPage * this.perPage) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
      this.pageOf = this.totalRows

      return paginatedItems
    },
  },
  watch: {
    selectedMessageOptions() {
      // this.fetchData()
    },
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },
  },
  created() {
    // console.log("UsersTabMessages created()")
  },
  mounted() {
    // console.log("UsersTabMessages mounted()")
    this.fetchData()
  },
  methods: {
    ...messagesStore.mapActions({
      $fetchMessages: FETCH_MESSAGES,
    }),
    async fetchData() {
      await Promise.all([
        this.$fetchMessages({
          site: this.siteSelected,
          userid: this.userData.userid,
          role: 'user',
          periodFrom: this.periodFrom.split(' ')[0],
          periodTo: this.periodTo.split(' ')[0],
          type: 'rcv',
        }),
        this.$fetchMessages({
          site: this.siteSelected,
          userid: this.userData.userid,
          role: 'user',
          periodFrom: this.periodFrom.split(' ')[0],
          periodTo: this.periodTo.split(' ')[0],
          type: 'snd',
        }),
      ])
      console.log('fetchMessagesSendHistory', this.fetchMessagesSendHistory.length)
      console.log('fetchMessagesReceiveHistory', this.fetchMessagesReceiveHistory.length)
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
    // Table
    fncToggleRowDetails(item) {
      console.log('fncToggleRowDetails', item)
      this.$set(item, '_showDetails', !item._showDetails)
    },
    fncReadsAndTotalCount(receivers) {
      const total = receivers.length
      const readCount = receivers.filter(receiver => receiver.isRead).length
      return `${readCount} / ${total} 명`
    },

    // Modal
    fncOpenModal(item) {
      this.receiversItems = item // 모달에 넘겨줄 데이터 설정
      this.$refs.messagesHistoySendReceiversInfoModal.show()
    },
    fncCloseModal() {
      this.receiversInfoModal = null
      this.$refs.messagesHistoySendReceiversInfoModal.hide()
    },
  },
}
</script>
<style lang="scss">
.per-page-selector {
  width: 90px;
}

/* 글자길이가 길어질경우 ...로 표시 */
.text-over {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
/* 웹에서 텍스트를 선택하는 기능을 비활성화하는 역할 */
.no-select {
  /* 표준 방식 */
  user-select: none;

  /* 벤더 프리픽스 추가 */
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}

//Modal
.modal-dialog {
  max-width: 90rem;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 2rem;
}

.table-scroll th {
  position: sticky;
  top: 0rem;
  background-color: #fff;
  z-index: 1;
}
</style>
