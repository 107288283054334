<template>
  <div>
    <!-- User Basic -->
    <b-card>
      <b-row>
        <!-- User Basic: Left col -->
        <b-col
          cols="12"
          lg="4"
          class="d-flex flex-column mb-1"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start mb-1">
            <b-avatar
              :src="user.role"
              :text="avatarText(user.role)"
              :variant="`light-${resolveUserRoleVariant(user.role)}`"
              size="88px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h3 class="mb-0 text-success">
                  {{ user.userid }}
                </h3>
                <span class="card-text">상위/추천: {{ user.recommendNickname }}</span>
                <div>
                  <b-badge
                    variant="dark"
                    style="font-size: xx-small"
                    type="button"
                    @click="submit('openUserView')"
                  >
                    <feather-icon
                      icon="LogInIcon"
                      class=""
                    />
                    접속
                  </b-badge>
                </div>
              </div>
            </div>
          </div>

          <!-- Upper Button Row -->
          <div
            class="d-flex flex-wrap"
            style="margin-bottom: 0.5rem"
          >
            <b-button
              v-b-tooltip.hover.v-danger="'회원정보를 수정합니다'"
              variant="outline-success"
              class=""
              style="width: 6rem; height: 2rem; margin-right: 0.5rem; padding: 2px"
              size="sm"
              @click="btnUpdate"
            >
              수정
            </b-button>
            <b-button
              v-b-modal.modal-sm
              v-b-tooltip.hover.v-danger="'회원의 비밀번호를 초기화 (변경값:1234)'"
              variant="outline-warning"
              class=""
              style="width: 6rem; height: 2rem; margin-right: 0.5rem; padding: 2px"
              size="sm"
              @click="buttonClick('resetPassword')"
            >
              PW초기화
            </b-button>
            <b-button
              v-b-tooltip.hover.v-danger="'접속한 회원을 로그아웃처리(강퇴)합니다'"
              variant="outline-warning"
              class=""
              style="width: 6rem; height: 2rem; margin-right: 0.5rem; padding: 2px"
              size="sm"
              @click="submit('updateUsersSleep')"
            >
              강퇴
            </b-button>
            <b-button
              v-b-tooltip.hover.v-danger="'휴먼계정으로 변경합니다'"
              variant="outline-warning"
              class=""
              style="width: 6rem; height: 2rem; margin-right: 0.5rem; padding: 2px"
              size="sm"
              @click="submit('updateUsersSleep')"
            >
              휴먼
            </b-button>
          </div>

          <!-- Lower Button Row -->
          <div class="d-flex flex-wrap">
            <b-button
              v-b-tooltip.hover.v-danger="'회원의 아이디와 ip를 차단합니다'"
              variant="outline-warning"
              class=""
              style="width: 6rem; height: 2rem; margin-right: 0.5rem; padding: 2px"
              size="sm"
              @click="submit('updateUsersBlock')"
            >
              차단
            </b-button>
            <b-button
              v-b-tooltip.hover.v-danger="'개인 쪽지보내기'"
              variant="outline-warning"
              class=""
              style="width: 6rem; height: 2rem; margin-right: 0.5rem; padding: 2px"
              size="sm"
              @click="$refs.UserViewUserMessages.$bvModal.show('UserViewUserMessages')"
            >
              쪽지
            </b-button>
            <b-button
              v-b-tooltip.hover.v-danger="'관리자 권한으로 포인트/캐시(스포츠)/캐시(카지노) 지급'"
              variant="outline-warning"
              class=""
              style="width: 6rem; height: 2rem; margin-right: 0.5rem; padding: 2px"
              size="sm"
              @click="$refs.UserViewUserAsset.$bvModal.show('UserViewUserAsset')"
            >
              지급
            </b-button>
            <b-button
              v-b-tooltip.hover.v-danger="'카지노/슬롯 ID 수동 생성'"
              variant="outline-warning"
              class=""
              style="width: 6rem; height: 2rem; margin-right: 0.5rem; padding: 2px"
              size="sm"
              @click="submit('createCasinoId')"
            >
              카지노ID생성
            </b-button>
          </div>
        </b-col>
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          lg="4"
        >
          <table class="mt-0 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">아이디 / 닉네임</span>
              </th>
              <td class="pb-50">
                {{ user.userid }} / {{ user.nickname }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">권한</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ user.role }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">등급</span>
              </th>
              <td class="pb-50">
                {{ user.rank }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">상태</span>
              </th>
              <td class="pb-50 text-capitalize">
                <!-- {{ user.status }} -->
                <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(user.status)}`"
                  class="text-capitalize"
                >
                  {{ convertUserStatus(user.status) }}
                </b-badge>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">전화번호</span>
              </th>
              <td class="pb-50">
                {{ user.mobile }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="MailIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">이메일</span>
              </th>
              <td>
                {{ user.email }}
              </td>
            </tr>
          </table>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group
            class="font-small-3 mt-1"
            label="회원 메모"
            label-for="notice-memo"
          >
            <b-form-textarea
              id="notice-memo"
              v-model="user.memo"
              v-b-modal.modal-lg
              class="font-small-3"
              size="sm"
              placeholder="회원메모"
              style="color:rgb(229,166,48)"
              rows="4"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="modal-lg"
      ref="userMemoModal"
      title="회원메모"
      centered
      button-size="sm"
      size="lg"
      ok-title="저장"
      @ok="submit(user.userid)"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          회원메모
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="$bvModal.hide('modal-lg')"
          />
        </div>
      </template>
      <label for="faq-detail2">메모수정</label>
      <b-row class="">
        <b-col cols="12">
          <b-form-textarea
            id="faq-detail2"
            v-model="user.memo"
            class="font-small-3"
            size="sm"
            placeholder="FAQ 상세내용"
            style="color:rgb(229,166,48)"
            rows="9"
          />
        </b-col>
      </b-row>
    </b-modal>
    <user-view-user-messages
      ref="UserViewUserMessages"
      :user="user"
    />
    <user-view-user-asset
      ref="UserViewUserAsset"
      :user="user"
    />

    <!-- User Stats -->
    <b-card>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          class="d-flex justify-content-between flex-column"
        >
          <div
            class="row mt-1 mb-1 flex-row justify-content-lg-start justify-content-between"
            style="margin-left: 1px;"
          >
            <div
              class="d-flex align-items-center mr-2"
              style="width: 120px; margin-bottom: 0.5rem"
            >
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0 font-small-4">
                  {{ Number(this.user.cash).toLocaleString() }}
                </h5>
                <small>캐쉬</small>
              </div>
            </div>
            <!--            <div-->
            <!--              class="d-flex align-items-center mr-2"-->
            <!--              style="width: 120px; margin-bottom: 0.5rem; margin-left: 4px;"-->
            <!--            >-->
            <!--              <b-avatar-->
            <!--                variant="light-success"-->
            <!--                rounded-->
            <!--              >-->
            <!--                <feather-icon-->
            <!--                  icon="DollarSignIcon"-->
            <!--                  size="18"-->
            <!--                />-->
            <!--              </b-avatar>-->
            <!--              <div class="ml-1">-->
            <!--                <h5 class="mb-0 font-small-4">-->
            <!--                  {{ Number(this.user.cashCasino).toLocaleString() }}-->
            <!--                </h5>-->
            <!--                <small>캐쉬(카지노)</small>-->
            <!--              </div>-->
            <!--            </div>-->
            <div
              class="d-flex align-items-center mr-2"
              style="width: 120px; margin-bottom: 0.5rem; margin-left: 4px;"
            >
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="BoxIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0 font-small-4">
                  {{ Number(this.user.point).toLocaleString() }}
                </h5>
                <small>보유 포인트</small>
              </div>
            </div>
            <div
              class="d-flex align-items-center mr-2"
              style="width: 120px; margin-bottom: 0.5rem; margin-left: 4px;"
            >
              <b-avatar
                variant="light-warning"
                rounded
              >
                <feather-icon
                  icon="BoldIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0 font-small-4">
                  {{ Number(this.user.betTotal).toLocaleString() }}
                </h5>
                <small>총 배팅</small>
              </div>
            </div>
            <div
              class="d-flex align-items-center mr-2"
              style="width: 120px; margin-bottom: 0.5rem; margin-left: 4px;"
            >
              <b-avatar
                variant="light-warning"
                rounded
              >
                <feather-icon
                  icon="AwardIcon"
                  size="18"
                />
              </b-avatar>
              <!-- 개발예정: 클릭 이벤트 -->
              <div
                v-b-tooltip.hover.bottom.v-danger="{ title: '적중(승): ' + betResultWin + '건 | 전체(승+패): ' + (betResultWin + betResultLoss) + '건', variant: 'dark'}"
                class="ml-1"
              >
                <h5 class="mb-0 font-small-4">
                  {{ (betResultWin / (betResultWin + betResultLoss) * 100).toFixed(2) }} %
                </h5>
                <small>승률</small>
              </div>
            </div>
            <div
              class="d-flex align-items-center mr-2"
              style="width: 120px; margin-bottom: 0.5rem; margin-left: 4px;"
            >
              <b-avatar
                variant="light-info"
                rounded
              >
                <feather-icon
                  icon="CornerRightDownIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0 font-small-4">
                  {{ Number(this.user.depositTotal).toLocaleString() }}
                </h5>
                <small>총 충전</small>
              </div>
            </div>
            <div
              class="d-flex align-items-center mr-2"
              style="width: 120px; margin-bottom: 0.5rem; margin-left: 4px;"
            >
              <b-avatar
                variant="light-info"
                rounded
              >
                <feather-icon
                  icon="CornerRightUpIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0 font-small-4">
                  {{ Number(this.user.exchangeTotal).toLocaleString() }}
                </h5>
                <small>총 환전</small>
              </div>
            </div>
            <div
              class="d-flex align-items-center mr-2"
              style="width: 120px; margin-bottom: 0.5rem; margin-left: 4px;"
            >
              <b-avatar
                variant="light-secondary"
                rounded
              >
                <feather-icon
                  icon="PlayIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0 font-small-4">
                  {{ Number(this.user.signinTotal).toLocaleString() }}
                </h5>
                <small>총 접속</small>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BButton, BFormTextarea,
  BBadge, BAvatar,
  BModal, VBModal, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_USER } from '@/store/users/action'
import { UPDATE_USERS_PASSWORD, UPDATE_USER } from '@/store/users/mutation'

import button from 'bootstrap/js/src/button'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import UserViewUserMessages from '@/views/apps/user/users-view/UserViewUserMessages.vue'
import UserViewUserAsset from '@/views/apps/user/users-view/UserViewUserAsset.vue'
import axios from 'axios'
import useUsersList from '../users-list/useUsersList'

const userStore = createNamespacedHelpers('userStore')
const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormTextarea,
    BBadge,
    BAvatar,
    BModal,

    UserViewUserMessages,
    UserViewUserAsset,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userView')),
      adminId: JSON.parse(localStorage.getItem('userData')).userid,
      adminIp: localStorage.getItem('ip'),
    }
  },
  setup() {
    const {
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      convertUserStatus,
    } = useUsersList()

    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      convertUserStatus,
    }
  },
  computed: {
    ...userStore.mapGetters({
      user: 'user',
    }),
    ...noticeStore.mapGetters({
      fetchRequestAlarmsBetFinish: 'fetchRequestAlarmsBetFinish',
    }),
    button() {
      return button
    },
    params() {
      return {
        userid: this.userData.userid,
        site: this.userData.site,
      }
    },
    betResultWin() {
      return this.fetchRequestAlarmsBetFinish.filter(item => item.betResult === 'WIN').length
    },
    betResultLoss() {
      return this.fetchRequestAlarmsBetFinish.filter(item => item.betResult === 'LOSS').length
    },
  },
  created() {
    // console.log("UserTabUserDeposit created()")
  },
  mounted() {
    // console.log("UserTabUserDeposit mounted()")
    this.fetchData()
    this.$socket.on('updateUserCash', data => {
      try {
        if (data) {
          console.log('data: ', data)
          this.user.cash = data.cash
          this.user.point = data.point
          console.log('updated cash')
        } else {
          console.error('데이터를 받지 못했습니다')
        }
      } catch (error) {
        console.error('데이터 파싱 오류: ', error)
      }
    })
  },
  methods: {
    ...userStore.mapActions({
      $fetchUser: FETCH_USER,
      $updateUsersPassword: UPDATE_USERS_PASSWORD,
      $updateUser: UPDATE_USER,
    }),
    async fetchData() {
      await this.$fetchUser(this.userData)
    },
    showToast(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'info',
        },
      })
    },
    validationForm(target) {
      this.submit(target)
    },
    buttonClick(target) {
      this.validationForm(target)
    },

    /* btn */
    btnUpdate() {
      this.$router.push({ name: 'apps-users-edit' })
    },

    /* step-01. */
    async submit(target) {
      switch (target) {
        case 'resetPassword':
          this.subTitle = '비밀번호를 초기화 하시겠습니까?\n (변경값 : 1234)'
          break
        case 'createCasinoId':
          this.subTitle = '카지노 ID를 수동으로 생성하시겠습니까?'
          break
        case 'openUserView':
          this.subTitle = '유저 페이지로 이동 하시겠습니까?'
          break
        default:
          this.subTitle = '변경 내용을 저장 하시겠습니까?'
          break
      }
      await Swal.fire({
        title: this.subTitle,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    /* step-02. */
    async confirmYes(target) {
      let flag = false
      let existId = ''
      // console.log("confirmYes", this.user)
      try {
        switch (target) {
          case 'resetPassword':
            this.$updateUsersPassword({
              site: this.user.site,
              userid: this.user.userid,
              password: '1234',
              adminId: this.adminId,
              adminIp: this.adminIp,
            })
            break
          case 'updateUsersSleep':
            console.log('bbb')
            // const events = { t: 'users', e: 'status', v: 'sleep' }
            this.$store.commit('userStore/updateParam', {
              t: 'user',
              e: 'status',
              v: 'sleep',
            })
            this.$updateUser({
              adminId: this.adminId,
              adminIp: this.adminIp,
            })
            break
          case 'updateUsersBlock':
            console.log('aaa')
            // const events = { t: 'users', e: 'status', v: 'block' }
            this.$store.commit('userStore/updateParam', {
              t: 'user',
              e: 'status',
              v: 'block',
            })
            this.$updateUser({
              adminId: this.adminId,
              adminIp: this.adminIp,
            })
            break
          case 'createCasinoId':
            existId = this.user.userid
            const username = this.user.site.substring(0, 3)
              .toLowerCase() + this.user.userid
            const apiUrl = `https://api.honorlink.org/api/user/create?username=${username}&nickname=${this.user.nickname}`
            try {
              const create = await axios.post(apiUrl, {}, {
                headers: {
                  accept: 'application/json',
                  Authorization: 'Bearer BNXNlcekSKk9MavaEYosnHwsAjsVDs6ymsTOQKhw',
                },
              })
            } catch (error) {
              flag = true
              console.error('Error create id:', error)
            }
            break
          case 'openUserView':
            console.log('111')
            break
          default:
            break
        }
      } catch {
        console.error()
      } finally {
        if (flag) {
          Swal.fire({
            title: `${existId}가 이미 있거나 생성에 실패했습니다.`,
            icon: 'warning',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        } else {
          Swal.fire({
            title: '저장 완료',
            icon: 'success',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        }
      }
    },
  },
}
</script>

<style>
.form-control[readonly] {
  color: #d4ff38 !important;
}
.tooltip .tooltip-inner{
  font-size: 12px !important;
}
</style>
