<template>
  <div>
    <b-card no-body>
      <b-row class="justify-content-between">
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            v-if="false"
            class="font-small-3"
            label="구분"
            label-for="search-select"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-md="left"
          >
            <b-form-select
              id="section-select"
              class="font-small-3 half-width"
              size="sm"
              style="color:rgb(229,166,48) !important; opacity: 0.7;"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            v-if="true"
            class="font-small-3"
            label="변경일자"
            label-for="search-period"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group
              id="search-period"
              class="calendar-size font-small-3"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class=""
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class=""
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-row class="justify-content-between">
        <!-- Table Fitler -->
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="Show"
            label-for="table-perPage"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-sm="left"
          >
            <b-form-select
              id="table-perPage"
              v-model="perPage"
              :options="perPageOptions"
              size="sm"
              :clearable="false"
              class="half-width"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            label="검색"
            label-for="table-searchFilter"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group size="sm">
              <b-form-input
                id="table-searchFilter"
                v-model="searchFilter"
                type="search"
                placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Table Content -->
        <b-col sm="12">
          <b-table
            responsive
            striped
            hover
            small
            show-empty
            empty-text="No matching records found"
            class="no-select"
            style="text-align: center; font-size: small"
            :items="paginatedItems"
            :fields="userUpdateLogsFields"
          >
            <template #cell(no)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 3rem"
              >
                {{ data.item.idx }}
              </div>
            </template>
            <template #cell(category)="data">
              <div style="min-width: 7rem">
                {{ data.item.category }}
              </div>
            </template>
            <template #cell(categoryDetail)="data">
              <div style="min-width: 9rem">
                <div>{{ data.item.categoryDetail.split('-')[0] }}</div>
                <div>{{ data.item.categoryDetail.split('-')[1] }}</div>
              </div>
            </template>
            <template #cell(adminInfo)="data">
              <div
                size="sm"
                style="align-items: center; min-width: 9rem; text-align: left;"
              >
<!--                <span-->
<!--                  v-if="data.item.adminIp.length > 14"-->
<!--                  v-b-tooltip.hover-->
<!--                  :title="data.item.adminIp"-->
<!--                >-->
<!--                  {{ data.item.adminid }}({{ data.item.adminIp.slice(0, 14) }}...)-->
<!--                </span>-->
<!--                <span v-else>-->
                  {{ data.item.adminid }}({{ data.item.adminIp }})
<!--                </span>-->
              </div>
            </template>
            <template #cell(target)="data">
              <div style="min-width: 6rem">
                {{ data.item.targetUserid }}
              </div>
            </template>
            <template #cell(tran)="data">
              <div style="min-width: 5rem">
                {{ data.item.tran }}
              </div>
            </template>
            <template #cell(asisContent)="data">
              <div style="min-width: 10rem; max-width: 20rem; text-align: left;">
                <div
                  v-for="item in data.item.asisContent.split('|')"
                  :key="item"
                >
                  <li>{{ item }}</li>
                </div>
              </div>
            </template>
            <template #cell(tobeContent)="data">
              <div style="min-width: 20rem; max-width: 20rem; text-align: left; white-space: pre-wrap; font-size: x-small;">
                <div
                  v-for="(item, index) in data.item.tobeContent.split('|')"
                  :key="item"
                  :style="{ color: item === data.item.asisContent.split('|')[index] ? '' : 'yellow' }"
                >
                  <li v-b-tooltip.hover :title="formatItem(item)" v-html="formatItem(item)"></li>
                </div>
              </div>
            </template>
            <template #cell(memo)="data">
              <div
                v-if="(data.item.memo || '').length > 8"
                style="min-width: 14rem; max-width: 30rem; text-align: left"
              >
                <span
                  v-b-tooltip.hover
                  :title="data.item.memo"
                >
                  {{ data.item.memo.slice(0, 8) }}...
                </span>
              </div>
              <div
                v-else
                style="min-width: 14rem; max-width: 30rem; text-align: left"
              >
                {{ data.item.memo }}
              </div>
            </template>
            <template #cell(createdAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 9rem"
              >
                {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
          </b-table>
        </b-col>

        <!-- Table Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
          style="margin-bottom: 0.5rem;"
        >
          <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} 건 </span>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend,
  BFormSelect,
  BButton, BFormDatepicker,
  BTable, BPagination, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_USER_UPDATE_LOGS } from '@/store/users/action'
import * as moment from 'moment-timezone'

const userStore = createNamespacedHelpers('userStore')

moment.tz.setDefault('Asia/Seoul')

export default {
  name: 'UserTabUserUpdateLogs',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BButton,
    BFormDatepicker,
    BTable,
    BPagination,
    BTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,

      userData: JSON.parse(localStorage.getItem('userView')),

      // Searh Conditon
      periodFrom: moment(new Date()).startOf('month').format('YYYY-MM-DD HH:mm'), // 당월 첫째날
      periodTo: moment(new Date()).format('YYYY-MM-DD HH:mm'), // 당월 금일

      // TableOption
      searchFilter: '',
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)
      isExpandChecked: false,

      // TableContent
      userUpdateLogsItems: [],
      userUpdateLogsFields: [
        { key: 'no', sortable: false, label: 'No.' },
        { key: 'site', sortable: false, label: '사이트' },
        { key: 'target', sortable: false, label: '변경대상' },
        { key: 'createdAt', sortable: false, label: '일시' },
        { key: 'category', sortable: false, label: '유형' },
        { key: 'categoryDetail', sortable: false, label: '상세' },
        { key: 'adminInfo', sortable: false, label: '수행자' },
        { key: 'tran', sortable: false, label: '구분' },
        { key: 'asisContent', sortable: false, label: '원본' },
        { key: 'tobeContent', sortable: false, label: '변경' },
        { key: 'memo', sortable: false, label: '메모' },
      ],
    }
  },
  computed: {
    ...userStore.mapGetters({
      fetchUserUpdateLogs: 'fetchUserUpdateLogs',
    }),

    // 검색필터된 Items
    filteredItems() {
      let filteredItems = this.fetchUserUpdateLogs
      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      // console.log('filteredItems', filteredItems)
      return filteredItems
    },

    // 검색필터된 Items => 페이징처리된 Item
    paginatedItems() {
      const totalFilteredItems = this.filteredItems.length
      const start = totalFilteredItems - (this.currentPage * this.perPage)
      const end = start + this.perPage

      const paginatedItems = this.filteredItems.slice(Math.max(0, start), end).reverse()

      this.totalRows = totalFilteredItems
      this.pageFrom = (this.currentPage - 1) * this.perPage + 1 // 현재 페이지의 첫 번째 항목
      this.pageTo = Math.min(this.totalRows, this.currentPage * this.perPage) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
      this.pageOf = this.totalRows

      return paginatedItems
    },
  },
  watch: {
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    this.fetchData()
  },
  methods: {
    ...userStore.mapActions({
      $fetchUserUpdateLogs: FETCH_USER_UPDATE_LOGS,
    }),
    formatItem(item) {
      // +-를 -로 변경
      return item.replace(/\+-/g, '-')
    },
    async fetchData() {
      await this.$fetchUserUpdateLogs({
        site: this.userData.site,
        userid: this.userData.userid,
        periodFrom: this.periodFrom.split(' ')[0],
        periodTo: this.periodTo.split(' ')[0],
      })
      this.userUpdateLogsItems = this.fetchUserUpdateLogs
    },
  },
}
</script>

<style>
.tooltip-inner {
  font-size: 1.25rem; /* 툴팁 글씨 크기 조정 */
  max-width: 500px; /* 툴팁 최대 너비 조정 */
}
</style>
