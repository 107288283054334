<template>
  <div>
    <b-card no-body>
      <!-- Search Condition -->
      <b-row class="justify-content-between">
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="구분"
            label-for="betHistory-select"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-md="left"
          >
            <b-form-select
              id="betHistory-select"
              v-model="fetchSelected1"
              class="font-small-3"
              :options="option1"
              size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            label="생성일시"
            label-for="betHistory-period"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group
              id="betHistory-period"
              class="calendar-size font-small-3"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class=""
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class=""
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Table -->
      <b-row class="justify-content-between">
        <!-- Table Fitler -->
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="Show"
            label-for="betHistory-perPage"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-md="left"
          >
            <b-form-select
              id="betHistory-perPage"
              v-model="perPage"
              class="half-width"
              size="sm"
              :options="perPageOptions"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            label="검색"
            label-for="betHistory-searchFilter"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group size="sm">
              <b-form-input
                id="betHistory-searchFilter"
                v-model="searchFilter"
                type="search"
                placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Table Content -->
        <b-table
          responsive
          show-empty
          hover
          small
          empty-text="No matching records found"
          :items="paginatedItems"
          :fields="fields1"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          style="text-align: center; font-size: small"
          :current-page="currentPage"
          @filtered="onFiltered"
        >
          <template
            #cell(no)="data"
            size="sm"
            style="text-align: center; max-width: 1rem"
          >
            {{ data.index + 1 }}
          </template>
          <template #cell(site)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 4rem"
            >
              {{ data.item.site }}
            </div>
          </template>
          <template #cell(id)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 4rem"
            >
              {{ data.item.id }}
            </div>
          </template>
          <template
            #cell(type)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.type }}
            </div>
          </template>
          <template
            #cell(username)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.username.slice(3) }}
            </div>
          </template>
          <template
            #cell(amount)="data"
          >
            <div
              size="sm"
              style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
            >
              {{ Number(data.item.amount).toLocaleString() }}
            </div>
          </template>
          <template
            #cell(refererId)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.refererId }}
            </div>
          </template>
          <template
            #cell(processedAt)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 8rem"
            >
              {{ data.item.processedAt }}
            </div>
          </template>
          <template
            #cell(targetId)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.targetId }}
            </div>
          </template>
          <template
            #cell(targetUsername)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.targetUsername }}
            </div>
          </template>
          <template
            #cell(targetBalance)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.targetBalance }}
            </div>
          </template>
          <template
            #cell(detailId)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.detailId }}
            </div>
          </template>
          <template #cell(round)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.round"
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ truncateText(data.item.round, 15) }}
            </div>
          </template>
          <template
            #cell(title)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 5rem"
            >
              {{ data.item.title }}
            </div>
          </template>
          <template
            #cell(detailType)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.detailType }}
            </div>
          </template>
          <template
            #cell(vendor)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.vendor }}
            </div>
          </template>
          <template
            #cell(createdAt)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.createdAt }}
            </div>
          </template>
          <template
            #cell(updatedAt)="data"
          >
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.updatedAt }}
            </div>
          </template>

          <template
            v-if="fetchGameCasinoTransaction.length > 0"
            slot="bottom-row"
          >
            <td
              colspan="5"
              style="background-color: #24233a; color:rgb(196,186,138)"
            >
              Total
            </td>
            <td
              class="text-right"
              style="background-color: #24233a; color:rgb(196,186,138)"
            >
              <div
                class="text-right"
                style="color: #3df9ff"
              >
                {{ Number(getTotal('amount')).toLocaleString() }}
              </div>
            </td>
            <td
              class="text-right"
              style="background-color: #24233a; color:rgb(196,186,138)"
            />
            <td
              colspan="3"
              class="text-right"
              style="background-color: #24233a; color:rgb(196,186,138)"
            />
          </template>
        </b-table>

        <!-- Table Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
          style="margin-bottom: 0.5rem;"
        >
          <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} 건 </span>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormCheckbox,
  BFormDatepicker, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_GAME_CASINO_TRANSACTION,
} from '@/store/game/action'
import * as moment from 'moment-timezone'
import router from '@/router'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormCheckbox,
    BFormDatepicker,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userView')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      userIp: localStorage.getItem('ip'),
      fetchSelected1: '',
      option1: [
        { value: '', text: '전체' },
        { value: 'win', text: '당첨' },
        { value: 'bet', text: '배팅' },
        { value: 'cancel', text: '취소' },
      ],
      searchFilter: '',
      perPage: 10,
      pageFrom: 0,
      perPageOptions: [10, 25, 50, 100],
      totalRows: 1,
      notReadCnt: 0,
      currentPage: 1,
      pageTo: 0,
      pageOf: 0,
      sortBy: 'processedAt',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      disabled: false,
      periodFrom: moment(new Date()).startOf('month').format(fmt1),
      periodTo: moment(new Date()).endOf('month').format(fmt1),
      searchOption: 'username',
      inputValue: '',
      searchOptionTitle: [
        { value: 'username', text: '유저명' },
      ],
      fields1: [
        {
          key: 'no', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'site', sortable: false, label: '사이트', thClass: 'col1',
        },
        {
          key: 'username', sortable: false, label: '아이디', thClass: 'col5',
        },
        {
          key: 'processedAt', sortable: false, label: '발생일시', thClass: 'col2',
        },
        {
          key: 'type', sortable: false, label: '구분', thClass: 'col1',
        },
        {
          key: 'amount', sortable: false, label: '금액', thClass: 'col3',
        },
        {
          key: 'id', sortable: false, label: '게임ID', thClass: 'col1',
        },
        {
          key: 'refererId', sortable: false, label: '참조ID', thClass: 'col2',
        },
        {
          key: 'title', sortable: false, label: '게임제목', thClass: 'col3',
        },
        {
          key: 'detailType', sortable: false, label: '제목상세', thClass: 'col6',
        },
        {
          key: 'vendor', sortable: false, label: '벤더', thClass: 'col6',
        },
      ],
    }
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData(this.fetchSelected1)
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
    fetchSelected1(newVal) {
      this.fetchSelected1 = newVal
      this.fetchData(newVal)
    },
    periodFrom() {
      this.fetchData(this.fetchSelected1)
    },
    periodTo() {
      this.fetchData(this.fetchSelected1)
    },
  },
  created() {
    this.init()
  },
  computed: {
    ...gameStore.mapGetters({
      fetchGameCasinoTransaction: 'fetchGameCasinoTransaction',
    }),

    // 검색필터된 Items
    filteredItems() {
      let filteredItems = this.fetchGameCasinoTransaction

      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }

      if (this.periodFrom && this.periodTo) {
        filteredItems = filteredItems.filter(item => moment(item.processedAt).isBetween(moment(this.periodFrom), moment(this.periodTo), undefined, '[]'))
      }
      // console.log('filteredItems', filteredItems)
      return filteredItems
    },

    // 검색필터된 Items => 페이징처리된 Items
    paginatedItems() {
      const totalFilteredItems = this.filteredItems.length
      const start = totalFilteredItems - (this.currentPage * this.perPage)
      const end = start + this.perPage

      const paginatedItems = this.filteredItems.slice(Math.max(0, start), end).reverse()

      this.totalRows = totalFilteredItems
      this.pageFrom = (this.currentPage - 1) * this.perPage + 1 // 현재 페이지의 첫 번째 항목
      this.pageTo = Math.min(this.totalRows, this.currentPage * this.perPage) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
      this.pageOf = this.totalRows

      return paginatedItems
    },
  },
  mounted() {
    this.fetchData(this.fetchSelected1)
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameCasinoTransaction: FETCH_GAME_CASINO_TRANSACTION,
    }),
    init() {
    },
    async fetchData(fetchSelected) {
      // const mySite = this.userData.site
      const periodFrom = this.periodFrom ? `${moment(this.periodFrom).format('YYYY.MM.DD')} 00:00:00` : ''
      const periodTo = this.periodTo ? `${moment(this.periodTo).format('YYYY.MM.DD')} 23:59:59` : ''
      const username = this.siteSelected.substring(0, 3).toLowerCase() + this.userData.userid
      await this.$fetchGameCasinoTransaction({
        site: this.siteSelected,
        username,
        periodFrom,
        periodTo,
        type: fetchSelected,
      })
      this.totalRows = this.fetchGameCasinoTransaction.length
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // Sleep function
    async sleep(ms) {
      const wakeUpTime = Date.now() + ms
      while (Date.now() < wakeUpTime) {}
    },
    getTotal(field) {
      return this.paginatedItems.reduce((acc, item) => acc + item[field], 0)
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.divSelecter {
  width: 180px;
}
</style>
