<template>
  <b-card>
    <div style="margin: -20px">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <b-form-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            size="sm"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
        </b-col>
        <b-col
          cols="6"
          lg="6"
          md="6"
          sm="12"
          class=""
        >
          <b-form-group
            class="font-small-3"
            label="접속일시"
            label-for="period"
            label-cols-md="2"
          >
            <b-input-group
              name="period"
              class="calendar-size font-small-3"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class=""
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class=""
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
        </b-col>
        <b-col
          class="right"
          cols="12"
          md="6"
        >
          <b-form-group
            class="mb-1"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="searchFilter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Table Content -->
        <b-col sm="12">
          <b-table
            responsive
            striped
            hover
            small
            show-empty
            empty-text="No matching records found"
            :items="paginatedItems"
            :fields="fields1"
            style="text-align: center !important; font-size: small"
          >
            <template #cell(ip)="data">
              <div
                v-b-tooltip.hover.bottom.v-danger="data.item.ip"
              >
                {{ truncateText(data.item.ip, 15) }}
              </div>
            </template>
            <template #cell(browser)="data">
              <div>
                {{ data.item.browser }}
              </div>
              <div>
                {{ data.item.browserVer }}
              </div>
            </template>
            <template #cell(networkCo)="data">
              <div style="min-width: 10rem">
                <div
                  v-b-tooltip.hover.bottom.v-danger="data.item.networkCo"
                >
                  {{ truncateText(data.item.networkCo, 15) }}
                </div>
                <div>
                  {{ data.item.networkType }}
                </div>
              </div>
            </template>
            <template #cell(asn)="data">
              <div>
                {{ data.item.asn }}
              </div>
              <div
                v-b-tooltip.hover.bottom.v-danger="data.item.route"
              >
                {{ truncateText(data.item.route, 15) }}
              </div>
            </template>
            <template #cell(os)="data">
              <div>
                {{ data.item.os }}
              </div>
              <div>
                {{ data.item.osVer }}
              </div>
            </template>
            <template #cell(latitude)="data">
              <b-link>
                <div
                  class="text-info"
                  style="text-align: center !important"
                  @click="googleMap(data.item.latitude.toLocaleString(), data.item.longitude.toLocaleString())"
                >
                  {{ data.item.latitude }}
                </div>
                <div
                  class="text-info"
                  style="text-align: center !important"
                  @click="googleMap(data.item.latitude.toLocaleString(), data.item.longitude.toLocaleString())"
                >
                  {{ data.item.longitude }}
                </div>
              </b-link>
            </template>
            <template #cell(flag)="data">
              <div style="min-width: 1rem">
                <img
                  class="flag"
                  style="width: 20px;"
                  :src="data.item.flag"
                >
              </div>
            </template>
            <template #cell(country)="data">
              <div style="min-width: 7rem">
                <div v-if="data.item.country !== 'South Korea'">
                  <div class="text-primary">
                    {{ data.item.country }}
                  </div>
                  <div
                    v-b-tooltip.hover.bottom.v-danger="data.item.city"
                    class="text-primary"
                  >
                    {{ truncateText(data.item.city, 15) }}
                  </div>
                </div>
                <div v-else>
                  <div>
                    {{ data.item.country }}
                  </div>
                  <div
                    v-b-tooltip.hover.bottom.v-danger="data.item.city"
                  >
                    {{ truncateText(data.item.city, 15) }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(createdAt)="data">
              <div style="min-width: 7rem">
                {{ data.value | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
          </b-table>
        </b-col>

        <!-- Table Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
          style="margin-bottom: 0.5rem;"
        >
          <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} 건 </span>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend,
  BFormSelect,
  BButton,
  BTable, BPagination,
  BLink,
  VBTooltip,
  BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_SIGNIN_LOGS } from '@/store/logs/action'
import * as moment from 'moment-timezone'
moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

const logStore = createNamespacedHelpers('logStore')

export default {
  name: 'UserTabSigninLog',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BButton,
    BTable,
    BPagination,
    BLink,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userView')),

      // TableOption
      searchFilter: '',
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)
      periodFrom: '',
      periodTo: '',
      fields1: [
        { key: 'id', sortable: false, label: 'No.' },
        { key: 'site', sortable: false, label: 'Site' },
        { key: 'userid', sortable: false, label: '아이디' },
        { key: 'ip', sortable: false, label: '접속IP' },
        { key: 'browser', sortable: false, label: '브라우저' },
        { key: 'os', sortable: false, label: 'OS' },
        { key: 'platform', sortable: false, label: '플랫폼' },
        { key: 'networkCo', sortable: false, label: '통신사' },
        { key: 'asn', sortable: false, label: 'ASN/route' },
        { key: 'flag', sortable: false, label: '국가' },
        { key: 'country', sortable: false, label: '도시' },
        { key: 'latitude', sortable: false, label: '위도/경도' },
        { key: 'createdAt', sortable: false, label: '접속일시' },
      ],
    }
  },
  watch: {
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },
  },
  computed: {
    ...logStore.mapGetters({
      fetchSigninLogsItems: 'fetchSigninLogsItems',
    }),

    // 검색필터된 Items
    filteredItems() {
      let filteredItems = this.fetchSigninLogsItems

      if (this.userData.userid) {
        filteredItems = filteredItems.filter(item => item.userid === this.userData.userid)
      }

      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      return filteredItems
    },

    // 검색필터된 Items => 페이징처리된 Item
    paginatedItems() {
      const totalFilteredItems = this.filteredItems.length
      const start = totalFilteredItems - (this.currentPage * this.perPage)
      const end = start + this.perPage

      const paginatedItems = this.filteredItems.slice(Math.max(0, start), end).reverse()

      this.totalRows = totalFilteredItems
      this.pageFrom = (this.currentPage - 1) * this.perPage + 1 // 현재 페이지의 첫 번째 항목
      this.pageTo = Math.min(this.totalRows, this.currentPage * this.perPage) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
      this.pageOf = this.totalRows

      return paginatedItems
    },
  },
  created() {
    this.init()
  },
  mounted() {
    // console.log("UserTabSigninLog mounted()")
    this.fetchData()
  },
  methods: {
    ...logStore.mapActions({
      $fetchSigninLogs: FETCH_SIGNIN_LOGS,
    }),
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
    async fetchData() {
      await this.$fetchSigninLogs({
        ...this.userData,
        periodFrom: this.periodFrom ? (this.periodFrom).split(' ')[0] : '',
        periodTo: this.periodTo ? (this.periodTo).split(' ')[0] : '',
      })
    },
    init() {
      this.periodFrom = moment(new Date()).startOf('month').format(fmt1)
      this.periodTo = moment(new Date()).endOf('month').format(fmt1)
    },
    // ##.googleMap init
    async googleMap(la, lo) {
      const vla = parseFloat(la).toFixed(6)
      const vlo = parseFloat(lo).toFixed(6)
      const url = `https://maps.google.com/maps?q=${vla},+${vlo}`
      window.open(url)
    },
  },
}
</script>
