import { extend, localize } from 'vee-validate'

export default async function validation(refs) {
  // between 규칙 재정의
  extend('between', {
    validate(value, { min, max }) {
      // value의 길이가 min과 max 사이인지 확인
      return value.length >= min && value.length <= max
    },
    params: ['min', 'max'], // 규칙에 필요한 매개변수
    // Messages: '값은 {min}과 {max} 사이의 길이여야 합니다.' // 오류 메시지
  })

  extend('requiredAssetType', {
    validate(value) {
      console.log(value)
      debugger
      // 특정 조건에 따라 유효성 검사를 수행
      // 이 예제에서는 값이 "required" 필드와 같을 때만 유효성 검사를 통과하도록 설정
      return value === this.vm.$data.selectedAssetType
    },
    message: '입력값이 필요합니다.',
  })

  // messages 규칙 재정의
  localize({
    en: {
      messages: {
        required: '"{_field_}" 값은 필수 항목입니다.',
        between: '"{_field_}" 값은 {min}과 {max} 사이여야 합니다.',
        length: '"{_field_}" 값은 {length}자리여야 합니다.',
        // ... 다른 메시지 추가 가능
      },
    },
  })

  try {
    // 유효성 검사 통과
    return refs.validate()
  } catch (error) {
    // 유효성 검사 실패
    return false
  }
}
